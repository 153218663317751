import React from "react";

import UserList from "../components/UserList";

const UsersPage = () => {
  return (
    <div className="container m-auto p-5">
      <UserList />
    </div>
  );
};

export default UsersPage;
