import React, { useContext, useState, useEffect } from "react";
import TicketContext from "../context/TicketContext";
import { useParams } from "react-router-dom";
import formatDate from "../lib/formatDate"; // Adjust the path accordingly

import { Button } from "./ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import { Badge } from "./ui/badge";
import { Label } from "./ui/label";
import { Textarea } from "./ui/textarea";

import { useToast } from "./ui/use-toast";
import { ScrollArea } from "./ui/scroll-area";
import { Separator } from "./ui/separator";
import SkeletonCard from "./SkeletonCard";

const TicketDetails = ({ match }) => {
  const { toast } = useToast();
  const { selectedTicket, addNote, getTicketById } = useContext(TicketContext);
  const [note, setNote] = useState("");
  const { id } = useParams();

  useEffect(() => {
    getTicketById(id);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    addNote(id, note);
    setNote("");
    toast({
      title: "Notes Addes ",
      description: note,
    });
  };

  if (!selectedTicket) return <SkeletonCard />;

  return (
    <div className="w-full lg:grid lg:min-h-[600px] lg:grid-cols-2 xl:min-h-[670px] ">
      <div
        className="p-9 flex hidden  lg:block align-middle
      justify-center content-center "
      >
        <Card className="mb-4">
          <CardHeader>
            <CardTitle>Ticket Title: {selectedTicket.title}</CardTitle>
            <CardDescription>
              Ticket Description: {selectedTicket.description}
            </CardDescription>

            <Badge variant={selectedTicket.status}>
              {selectedTicket.status}
            </Badge>
          </CardHeader>
          <CardContent>
            {/* scroll area */}
            <ScrollArea className=" h-72 rounded-md border">
              <div className="p-4">
                <h4 className="mb-4 text-sm font-medium leading-none">Notes</h4>
                {selectedTicket.notes.map((note, _id) => (
                  <div key={_id}>
                    <div className="text-sm">{note.text}</div>
                    <div className="text-right text-sm">
                      <Badge variant={note.addedBy}>
                        {note.addedBy === "Admin" ? "Admin" : "You"}
                      </Badge>
                    </div>

                    <div className="text-right text-sm">
                      {formatDate(note.date)}
                    </div>
                    <Separator className="my-2" />
                  </div>
                ))}
              </div>
            </ScrollArea>
            {/* scroll area end */}
          </CardContent>
        </Card>
      </div>
      {/* add note */}
      <div
        className=" p-9 flex hidden bg-muted lg:block align-middle
      justify-center content-center "
      >
        <Card className=" bg-gray-400">
          <CardHeader>
            <CardTitle className="text-2xl">Add Message</CardTitle>
            <CardDescription></CardDescription>
          </CardHeader>
          <form onSubmit={handleSubmit}>
            <CardContent className="grid gap-4">
              <div className="grid gap-2">
                <Label htmlFor="note">Message</Label>
                <Textarea
                  id="note"
                  type="text"
                  required
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  placeholder="Add a note..."
                />
              </div>
            </CardContent>
            <CardFooter>
              <Button className="w-full" type="submit">
                Add Message
              </Button>
            </CardFooter>
          </form>
        </Card>
      </div>
      {/* add note end */}
    </div>
  );
};

export default TicketDetails;
