import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import AuthContext from "../context/AuthContext";

import { Button } from "../components/ui/button";
import logo from "../logo3.jpg";
import { Input } from "../components/ui/input";
import { Label } from "../components/ui/label";
import { validatePassword } from "../lib/validatePassword";
const ResetPassword = () => {
  const { token } = useParams();
  const { resetPassword, message, error, clearMessages } =
    useContext(AuthContext);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  useEffect(() => {
    if (message || error) {
      // Clear the message after 5 seconds
      const timer = setTimeout(() => {
        clearMessages();
      }, 5000);

      // Clear the timeout if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [message, error, clearMessages]);

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    if (!validatePassword(newPassword)) {
      setPasswordError(
        "Password must be at least 6 characters long, with upper and lower case letters, numbers, and special characters."
      );
    } else {
      setPasswordError("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (passwordError) {
      alert("Please fix the password error before submitting");
      return;
    }
    await resetPassword(token, password);
  };

  return (
    <div className="w-full lg:grid lg:min-h-[calc(100vh-80px)] lg:grid-cols-2 xl:min-h-[calc(100vh-80px)]">
      <div className="flex items-center justify-center py-12">
        <div className="mx-auto grid w-[350px] gap-6">
          <div className="grid gap-2 text-center">
            <h1 className="text-3xl font-bold">Reset Your Password</h1>
            <p className="text-balance text-muted-foreground">
              Please Enter your new password
            </p>
          </div>
          <div className="grid gap-4">
            <form onSubmit={handleSubmit}>
              <div className="grid gap-2">
                <Label htmlFor="password">password</Label>
                <Input
                  id="password"
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                  required
                />
              </div>

              <div className="mt-4">
                <Button type="submit" className="w-full">
                  Reset Password
                </Button>
              </div>
            </form>
          </div>
          <div className="mt-4 text-center text-sm">
            {passwordError && <span>{passwordError}</span>}
            {message && <p className="text-green-500">{message}</p>}
            {error && <p className="text-red-500">{error}</p>}
          </div>
        </div>
      </div>
      <div className="  flex hidden bg-muted lg:block align-middle justify-center content-center ">
        <img className="object-cover h-full w-full" src={logo} alt="Logo" />
      </div>
    </div>
  );
};

export default ResetPassword;
