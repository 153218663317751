import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { buttonVariants } from "./ui/button";
// import ThemeToggle from "../shadcn/ThemeToggle";
import AuthContext from "../context/AuthContext";
// import logo from "../logo2.svg";
import { Button } from "../components/ui/button";
import { Avatar, AvatarFallback, AvatarImage } from "../components/ui/avatar";

const logo = process.env.REACT_APP_COMPANY_LOGO_URL;
console.log(logo);

const Header = () => {
  const { user, logout } = useContext(AuthContext);

  return (
    <header className="h-[80px] p-4 flex justify-between items-center bg-[#141414]">
      <div className="w-[60px]">
        <Link to="/" className="text-xl font-bold">
          <img src={logo} alt="Logo" />
        </Link>
      </div>
      <div className="flex items-center space-x-4">
        {user ? (
          <>
            <Avatar>
              <AvatarImage src="https://api.dicebear.com/9.x/adventurer/svg" />
              <AvatarFallback>
                {user.name.slice(0, 2).toUpperCase()}
              </AvatarFallback>
            </Avatar>
            {user.role === "User" && (
              <Link
                className={buttonVariants({ variant: "outline" })}
                to="/tickets"
              >
                Tickets
              </Link>
            )}

            {user.role === "Admin" && (
              <>
                <Link
                  className={buttonVariants({ variant: "link" })}
                  to="/admin"
                >
                  Admin
                </Link>
                <Link
                  className={buttonVariants({ variant: "link" })}
                  to="/user-list"
                >
                  Users
                </Link>
              </>
            )}
            <Button onClick={logout} className="w-full" variant="outline">
              Logout
            </Button>
          </>
        ) : (
          <>
            <Link
              className={buttonVariants({ variant: "outline" })}
              to="/login"
            >
              Login
            </Link>
            <Link
              className={buttonVariants({ variant: "outline" })}
              to="/register"
            >
              Register
            </Link>
          </>
        )}
      </div>
    </header>
  );
};

export default Header;
