import React from "react";
import AdminDashboard from "../components/AdminDashboard";
import AdminTicketList from "../components/AdminTicketList";

const AdminPage = () => {
  return (
    <div className="container m-auto p-5">
      <AdminDashboard />
      <AdminTicketList />
    </div>
  );
};

export default AdminPage;
