import React, { createContext, useState, useContext, useEffect } from "react";
import axios from "axios";
import AuthContext from "./AuthContext";

const TicketContext = createContext();
const url = process.env.REACT_APP_BACKEND_URL;

export const TicketProvider = ({ children }) => {
  const { user } = useContext(AuthContext);
  const [tickets, setTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);

  useEffect(() => {
    if (user) {
      fetchTickets();
    }
  }, [user]);

  const fetchTickets = async () => {
    if (user.role === "Admin") {
      const { data } = await axios.get(`${url}/api/tickets`, {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      setTickets(data);
    } else {
      const { data } = await axios.get(`${url}/api/tickets/mytickets`, {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      setTickets(data);
    }
  };

  const createTicket = async (title, description) => {
    const { data } = await axios.post(
      `${url}/api/tickets`,
      { title, description },
      {
        headers: { Authorization: `Bearer ${user.token}` },
      }
    );
    setTickets([...tickets, data]);
  };

  const addNote = async (ticketId, text) => {
    const { data } = await axios.post(
      `${url}/api/tickets/${ticketId}/note`,
      { text },
      {
        headers: { Authorization: `Bearer ${user.token}` },
      }
    );

    updateTicketStatus(ticketId, "Open");
    setSelectedTicket(data);
    fetchTickets(); // Refresh the ticket list
  };

  const updateTicketStatus = async (ticketId, status, adminResponse = "") => {
    const { data } = await axios.put(
      `${url}/api/tickets/${ticketId}/status`,
      { status, adminResponse },
      {
        headers: { Authorization: `Bearer ${user.token}` },
      }
    );
    setTickets(
      tickets.map((ticket) => (ticket._id === ticketId ? data : ticket))
    );
  };

  const getTicketById = async (ticketId) => {
    try {
      const { data } = await axios.get(`${url}/api/tickets/${ticketId}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      setSelectedTicket(data);
    } catch (error) {
      console.error("Error fetching ticket:", error);
      setSelectedTicket(null);
    }
  };

  return (
    <TicketContext.Provider
      value={{
        tickets,
        createTicket,
        addNote,
        selectedTicket,
        setSelectedTicket,
        updateTicketStatus,
        getTicketById,
      }}
    >
      {children}
    </TicketContext.Provider>
  );
};

export default TicketContext;
