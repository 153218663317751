import React, { useContext } from "react";
import TicketContext from "../context/TicketContext";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";
import { Button } from "./ui/button";
import { Badge } from "./ui/badge";
import { Separator } from "./ui/separator";
import { Link } from "react-router-dom";

const AdminTicketList = () => {
  const { tickets, updateTicketStatus, setSelectedTicket } =
    useContext(TicketContext);

  const handleStatusChange = (ticketId, newStatus) => {
    updateTicketStatus(ticketId, newStatus);
  };

  return (
    <>
      <h2 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl mb-1 ">
        Ticket List
      </h2>
      <Separator className="my-2" />
      {/* table start */}
      <Table>
        <TableCaption>Tickets Raised</TableCaption>
        <TableHeader>
          <TableRow>
            <TableHead className="w-[100px]">Title</TableHead>
            <TableHead>Description</TableHead>
            <TableHead>Status</TableHead>
            <TableHead>Set Pending</TableHead>
            <TableHead>Set Closed</TableHead>
            <TableHead className="text-right">Open</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {tickets.map((ticket) => (
            <TableRow key={ticket._id}>
              <TableCell className="font-medium">{ticket.title}</TableCell>
              <TableCell>{ticket.description}</TableCell>
              <TableCell>
                <Badge variant={ticket.status}>{ticket.status}</Badge>
              </TableCell>
              <TableCell>
                <Button
                  className="w-full"
                  type="submit"
                  onClick={() => handleStatusChange(ticket._id, "Pending")}
                >
                  Set Pending
                </Button>
              </TableCell>
              <TableCell className="text-right">
                <Button
                  className="w-full"
                  type="submit"
                  onClick={() => handleStatusChange(ticket._id, "Closed")}
                >
                  Set Closed
                </Button>
              </TableCell>
              <TableCell className="text-right">
                <Link
                  to={`/tickets/${ticket._id}`}
                  onClick={() => setSelectedTicket(ticket)}
                >
                  Check
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {/* table end */}
    </>
  );
};

export default AdminTicketList;
