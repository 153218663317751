import React from "react";
import CreateTicket from "../components/CreateTicket";
import TicketList from "../components/TicketList";

const TicketsPage = () => {
  return (
    <div className="w-full lg:grid lg:min-h-[600px] lg:grid-cols-2 xl:min-h-[670px]">
      <div className="flex flex-row items-center justify-center py-12">
        <TicketList />
      </div>
      <div className=" p-9 flex hidden bg-muted lg:block align-middle justify-center content-center ">
        <CreateTicket />
      </div>
    </div>
  );
};

export default TicketsPage;
