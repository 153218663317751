// import React, { useContext } from "react";
// import { Navigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";

// const ProtectedRoute = ({ children }) => {
//   const { user, loading } = useContext(AuthContext);

//   if (loading) {
//     return <div>Loading...</div>; // You can replace this with a spinner or any loading indicator
//   }

//   if (!user) {
//     return <Navigate to="/login" />;
//   }

//   return children;
// };

// export default ProtectedRoute;

import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import Unauthorized from "./Unauthorized";
import SkeletonCard from "./SkeletonCard";
// import AuthContext from "./AuthContext";

const ProtectedRoute = ({ adminOnly = false, children }) => {
  const { user, loading } = useContext(AuthContext);

  if (loading) {
    return <SkeletonCard />; // You can replace this with a spinner or any loading indicator
  }

  if (!user) {
    // If no user is logged in, redirect to login
    return <Navigate to="/login" replace />;
  }

  if (adminOnly && user.role !== "Admin") {
    // If the route is admin-only and the user is not an admin, redirect to the unauthorized page or home
    return <Unauthorized />;
  }

  // If the user is authenticated and has the required role, render the requested route
  return children;
};

export default ProtectedRoute;
