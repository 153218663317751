import { Skeleton } from "./ui/skeleton";
const SkeletonCard = () => {
  return (
    <div className="flex w-screen h-[calc(100vh-80px)] justify-center align-middle items-center content-center">
      <div className="flex flex-col space-y-3">
        <Skeleton className="h-[125px] w-[250px] rounded-xl" />
        <Skeleton className="h-[125px] w-[250px] rounded-xl" />
        <div className="space-y-2">
          <Skeleton className="h-4 w-[250px]" />
          <Skeleton className="h-4 w-[200px]" />
        </div>
      </div>
    </div>
  );
};
export default SkeletonCard;
