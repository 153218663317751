import React, { useContext } from "react";
import AdminContext from "../context/AdminContext";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";
import { Badge } from "./ui/badge";
import { Separator } from "./ui/separator";

const UserList = () => {
  const { users } = useContext(AdminContext);

  return (
    <div className="container mx-auto p-4">
      <h2 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl mb-1 ">
        All Users
      </h2>
      <Separator className="my-2" />
      {users.length > 0 ? (
        // Add the table component here
        <>
          <Table>
            <TableCaption>List of all Users</TableCaption>
            <TableHeader>
              <TableRow>
                <TableHead className="w-[100px]">Name</TableHead>
                <TableHead>Email</TableHead>

                <TableHead className="text-right">Role</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user._id}>
                  <TableCell className="font-medium">{user.name}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell className="text-right">
                    <Badge variant={user.role}>{user.role}</Badge>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      ) : (
        <p>No users found.</p>
      )}
    </div>
  );
};

export default UserList;
