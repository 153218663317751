import React, { useContext, useEffect, useState } from "react";
import TicketContext from "../context/TicketContext";
import { Separator } from "./ui/separator";

const AdminDashboard = () => {
  const { tickets } = useContext(TicketContext);
  const [stats, setStats] = useState({ total: 0, pending: 0, completed: 0 });

  useEffect(() => {
    const pendingTickets = tickets.filter(
      (ticket) => ticket.status === "Pending"
    ).length;
    const completedTickets = tickets.filter(
      (ticket) => ticket.status === "Closed"
    ).length;
    const openTickets = tickets.filter(
      (ticket) => ticket.status === "Open"
    ).length;
    setStats({
      total: tickets.length,
      pending: pendingTickets,
      completed: completedTickets,
      open: openTickets,
    });
  }, [tickets]);

  return (
    <div className="container p-3">
      <h2 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl mb-2 ">
        Admin Dashboard
      </h2>
      <Separator className="my-2" />
      <div className="py-5 sm:py-5">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <dl className="grid grid-cols-1 gap-x-1 gap-y-16 text-center lg:grid-cols-4">
            <div className=" flex max-w-xs flex-col gap-y-4 border border-grey rounded p-3 bg-blue-300">
              <dt className="text-base leading-7 text-gray-600">
                Total Tickets
              </dt>
              <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                {stats.total}
              </dd>
            </div>
            <div className=" flex max-w-xs flex-col gap-y-4 border border-grey rounded p-3 bg-red-300">
              <dt className="text-base leading-7 text-gray-600">
                Open Tickets
              </dt>
              <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                {stats.open}
              </dd>
            </div>
            <div className=" flex max-w-xs flex-col gap-y-4 border border-grey rounded p-3 bg-yellow-100">
              <dt className="text-base leading-7 text-gray-600">
                Pending Tickets
              </dt>
              <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                {stats.pending}
              </dd>
            </div>
            <div className=" flex max-w-xs flex-col gap-y-4 border border-grey rounded p-3 bg-green-200">
              <dt className="text-base leading-7 text-gray-600">
                Completed Tickets
              </dt>
              <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                {stats.completed}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
