import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import AuthContext from "./AuthContext";

const AdminContext = createContext();
const url = process.env.REACT_APP_BACKEND_URL;
export const AdminProvider = ({ children }) => {
  const [users, setUsers] = useState([]);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const fetchUsers = async () => {
      if (user && user.role === "Admin") {
        try {
          const config = {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          };

          const { data } = await axios.get(`${url}/api/users/all`, config);
          setUsers(data);
        } catch (error) {
          console.error("Error fetching users:", error);
        }
      }
    };

    if (user) {
      fetchUsers();
    }
  }, [user, setUsers]); // Add setUsers as a dependency

  return (
    <AdminContext.Provider value={{ users }}>{children}</AdminContext.Provider>
  );
};

export default AdminContext;
