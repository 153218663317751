import React, { useContext } from "react";
import TicketContext from "../context/TicketContext";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";
import { buttonVariants } from "./ui/button";
import { Badge } from "./ui/badge";

const TicketList = () => {
  const { tickets, setSelectedTicket } = useContext(TicketContext);

  return (
    <div className="flex flex-row flex-wrap p-3">
      <h2 className="mb-8 scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl ">
        Your Tickets
      </h2>

      {/* table start */}
      <Table>
        <TableCaption>A list of your recent tickets.</TableCaption>
        <TableHeader>
          <TableRow>
            <TableHead className="w-[100px]">Title</TableHead>
            <TableHead>Description</TableHead>
            <TableHead>Status</TableHead>
            <TableHead className="text-right">Message</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {tickets.map((ticket) => (
            <TableRow key={ticket._id}>
              <TableCell className="font-medium">{ticket.title}</TableCell>
              <TableCell>{ticket.description}</TableCell>
              <TableCell>
                <Badge variant={ticket.status}>{ticket.status}</Badge>
              </TableCell>
              <TableCell className="text-right">
                <Link
                  className={buttonVariants({ variant: "link2" })}
                  to={`/tickets/${ticket._id}`}
                  onClick={() => setSelectedTicket(ticket)}
                >
                  Details
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {/* table end */}
    </div>
  );
};

export default TicketList;
