import React, { useState, useContext } from "react";
import TicketContext from "../context/TicketContext";
import { Button } from "./ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { Textarea } from "./ui/textarea";

const CreateTicket = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const { createTicket } = useContext(TicketContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    createTicket(title, description);
    setTitle("");
    setDescription("");
  };

  return (
    <>
      <Card className="mb-4 bg-gray-400">
        <CardHeader>
          <CardTitle className="text-2xl">Add Ticket</CardTitle>
          <CardDescription>Create A New Ticket</CardDescription>
        </CardHeader>
        <form onSubmit={handleSubmit}>
          <CardContent className="grid gap-4">
            <div className="grid gap-2">
              <Label htmlFor="Title">Title</Label>
              <Input
                id="text"
                placeholder="Ticket Title"
                required
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="grid gap-2">
              <Label htmlFor="message">Description</Label>
              <Textarea
                id="message"
                type="text"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
              />
            </div>
          </CardContent>
          <CardFooter>
            <Button className="w-full" type="submit">
              Create Ticket
            </Button>
          </CardFooter>
        </form>
      </Card>
    </>
  );
};

export default CreateTicket;
