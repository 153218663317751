import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const AuthContext = createContext();
const url = process.env.REACT_APP_BACKEND_URL;

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  let navigate = useNavigate();
  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    if (userInfo) {
      setUser(userInfo);
    }
    setLoading(false);
  }, []);

  const login = async (email, password) => {
    try {
      const { data } = await axios.post(`${url}/api/auth/login`, {
        email,
        password,
      });
      setUser(data);

      localStorage.setItem("userInfo", JSON.stringify(data));
      console.log(url);

      if (data.role === "Admin") {
        navigate("/admin");
      } else {
        navigate("/tickets");
      }
    } catch (error) {
      console.error("Login failed:", error);
      setError("Oops! Invalid email or password");
    }
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem("userInfo");
  };

  const register = async (name, email, password) => {
    try {
      const { data } = await axios.post(`${url}/api/auth/register`, {
        name,
        email,
        password,
      });
      setUser(data);
      localStorage.setItem("userInfo", JSON.stringify(data));
      navigate("/tickets");
    } catch (error) {
      console.error("Registration failed:", error);
      setError(
        "Oops! There was an issue with your Registration. Please try again in sometime!"
      );
    }
  };

  const forgotPassword = async (email) => {
    try {
      await axios.post(`${url}/api/auth/forgot-password`, { email });
      setMessage("Password reset link sent to your email");
    } catch (err) {
      setError("Failed to send password reset link");
    }
  };

  const resetPassword = async (token, password) => {
    try {
      await axios.post(`${url}/api/auth/reset-password/${token}`, { password });
      setMessage("Password reset successful");
      navigate("/login");
    } catch (err) {
      setError("Failed to reset password");
    }
  };
  const clearMessages = () => {
    setMessage(null);
    setError(null);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        register,
        loading,
        forgotPassword,
        resetPassword,
        message,
        error,
        clearMessages,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
