import React from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import { buttonVariants } from "./ui/button";

import { Link } from "react-router-dom";

export default function Unauthorized() {
  return (
    <div className="flex content-center align-middle justify-center items-center h-[calc(100vh-80px)]">
      <Card className="mb-4">
        <CardHeader>
          <CardTitle className="text-2xl text-center">
            Unauthorized Acces
          </CardTitle>
        </CardHeader>

        <CardContent className="grid gap-4 text-center">
          <h2 className="text-red-500">
            We are sorry but you're not authorized to veiw this page.
          </h2>
        </CardContent>
        <CardFooter className="justify-center">
          <Link
            className={buttonVariants({ variant: "outline" })}
            to="/tickets"
          >
            Go Back
          </Link>
        </CardFooter>
      </Card>
    </div>
  );
}
